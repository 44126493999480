// M odules
import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components/native'
import { Ionicons, MaterialIcons } from '@expo/vector-icons'

// Components
import { HeadTitle } from '../HeadTitle'

// Styles and Utils
import { dateFormat, numberFormat, mq } from '../../validation'
import { ShadowBox } from '../../generalStyles'
import { BtnContent, Container, SubTitle, TextEye, Title, Row } from './styled'
import { IconCreditApproved, IconCreditCancel, IconCreditPending, IconCreditReview, IconCreditSignature } from '../../../assets/icons'

/**
 * Head Repayment Plan Component
 * @param {any} props Properties RepaymentPlan
 * @returns {ReactElement} RepaymentPlanHeadV
 */
export const RepaymentPlanHeadV = ({ data: { credit, see, calculateFee }, handleOpen }) => {
    // States and Variables
    const theme = useTheme()
    return (
        <>
            <HeadTitle title='CRÉDITO' icon={<MaterialIcons name='attach-money' size={mq(26)} color={theme.PColor} />} />
            <Container style={ShadowBox}>
                <Row>
                    <Title color={theme.PColor}>{credit.cName}</Title>
                    {credit.cPending ? <IconCreditPending width={mq(30)} height={mq(30)} color={theme.SVColor} /> : ((credit.cState === 2 || credit.cState === 3 || credit.cState === 7) ? <IconCreditReview width={mq(30)} height={mq(30)} color={theme.PVColor} /> :
                        (credit.cState === 4 ? <IconCreditSignature width={mq(30)} height={mq(30)} color={theme.PColor} /> :
                            ((credit.cState === 5 || credit.cState === 1 || credit.cState === 6 || credit.cState === 13) ? <IconCreditApproved width={mq(30)} height={mq(30)} color={theme.PColor} /> :
                                (credit.cState === 0 && <IconCreditCancel width={mq(30)} height={mq(30)} color={theme.EColor} />)
                            )
                        ))
                    }
                </Row>
                <Row>
                    <SubTitle>Estado</SubTitle>
                    <SubTitle>{credit.cState === 2 || credit.cState === 3 ? 'En revisión' : credit.cState === 4 ? 'Por Confirmar' : credit.cState === 5 ? 'Fimar Documentos' : credit.cState === 6 ? 'Desembolsado' : credit.cState === 1 ? 'Finalizado' : credit.cState === 0 ? 'Rechazado' : credit?.cState === 13 ? 'Aprobado 360' : credit.cState === 7 ? 'En Aprobación de la Empresa' : ''}</SubTitle>
                </Row>
                <Row>
                    <SubTitle>Préstamo</SubTitle>
                    <SubTitle>{credit.cConsecutive}</SubTitle>
                </Row>
                {see &&
                    <>
                        {credit.creditFormat?.cfReqValAft !== 0 &&
                            <Row>
                                <SubTitle>V. Desembolso</SubTitle>
                                <SubTitle>$ {numberFormat(credit.cReqVal)}</SubTitle>
                            </Row>
                        }
                        {(credit.cModule !== 1 && credit.creditFormat?.cfValCreAft !== 0) &&
                            <Row>
                                <SubTitle>V. Crédito {(credit.cState !== 1 || credit.cState !== 0 || credit.cState !== 6) && 'estimada'}</SubTitle>
                                <SubTitle>$ {numberFormat(Math.round(credit.cValCre))}</SubTitle>
                            </Row>
                        }
                        {credit.creditFormat?.cfRatAft !== 0 &&
                            <Row>
                                <SubTitle>Tasa de Interés</SubTitle>
                                <SubTitle>{credit?.cRatVal.toFixed(2)} %</SubTitle>
                            </Row>
                        }
                        {credit.creditFormat?.cfValCouAft !== 0 &&
                            <Row>
                                <SubTitle>V. Cuota {(credit.cState !== 1 || credit.cState !== 0 || credit.cState !== 6) && 'estimada'}</SubTitle>
                                <SubTitle>$ {numberFormat(Math.round(credit.cValCou))}</SubTitle>
                            </Row>
                        }
                        {credit?.cState === 6 && (
                            <>
                                {calculateFee?.totalCollectionCostFee !== 0 &&
                                    <Row>
                                        <SubTitle>Gastos de cobranza</SubTitle>
                                        <SubTitle>$ {numberFormat(calculateFee?.totalCollectionCostFee)}</SubTitle>
                                    </Row>
                                }
                                {calculateFee?.totalInterestArrears !== 0 &&
                                    <Row>
                                        <SubTitle>Intereses de mora</SubTitle>
                                        <SubTitle>$ {numberFormat(calculateFee?.totalInterestArrears)}</SubTitle>
                                    </Row>
                                }
                                {calculateFee?.totalInterestArrears !== 0 &&
                                    <Row>
                                        <SubTitle>Total cuota estimada</SubTitle>
                                        <SubTitle>$ {numberFormat(calculateFee?.totalFee)}</SubTitle>
                                    </Row>
                                }
                            </>
                        )}
                        {credit.creditFormat?.cfFirFeeAft !== 0 &&
                            <Row>
                                <SubTitle>{credit.cModule === 1 ? 'Fecha de Pago' : 'Primera cuota'}</SubTitle>
                                <SubTitle>{credit.cFirFee ? dateFormat(credit.cFirFee) : 'N/A'}</SubTitle>
                            </Row>
                        }
                        {credit.creditFormat?.cfMonAft !== 0 &&
                            <Row>
                                <SubTitle>Cuotas pagadas</SubTitle>
                                <SubTitle>{credit.feeLength}</SubTitle>
                            </Row>
                        }
                    </>
                }
                <BtnContent onPress={handleOpen}>
                    <Ionicons name={see ? 'md-eye-off' : 'md-eye'} size={mq(15)} color={theme.SColor} />
                    <TextEye >{see ? 'Ver menos' : 'Ver más'}</TextEye>
                </BtnContent>
            </Container>
        </>
    )
}

RepaymentPlanHeadV.propTypes = {
    data: PropTypes.object,
    handleOpen: PropTypes.func,
}
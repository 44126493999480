
import { mili } from './mili/img'
import { bancaliv } from './bancaliv/img'
import { fibanca } from './fibanca/img'
import { finnvo } from './finnvo/img'
import { credipress } from './credipress/img'
import { liberate } from './liberate/img'
import { nauw } from './nauw/img'
import { creditoo } from './creditoo/img'
import { tusolucion } from './tusolucion/img'
import { fie } from './fie/img'
import { lenddi } from './lenddi/img'
import { gym } from './gym/img'
import { inversiones } from './inversiones/img'
// import Constants from 'expo-constants'
import { credinver } from './credinver/img'
import { selectClientName } from '../../ignore/utils'
import { freend } from './freend/img'
import { maxcredit } from './maxcredit/img'
import { sofi } from './sofi/img'
import { zali } from './zali/img'
import { libramoneda } from './libramoneda/img'
import { quickbank } from './quickbank/img'
import { finanzas } from './finanzas/img'
import { fembisol } from './fembisol/img'
import { suvalor } from './suvalor/img'
import { crecevalor } from './crecevalor/img'
import { mifianza } from './mifianza/img'
import { finzap } from './finzap/img'

const image = imageFront => {
    const client = selectClientName();

    if (client === 'bancaliv') {
        return bancaliv[imageFront]
    } else if (client === 'mifianza') {
        return mifianza[imageFront]
    } else if (client === 'finzap') {
        return finzap[imageFront]
    } else if (client === 'fembisol') {
        return fembisol[imageFront]
    } else if (client === 'crecevalor') {
        return crecevalor[imageFront]
    } else if (client === 'suvalor') {
        return suvalor[imageFront]
    } else if (client === 'fibanca') {
        return fibanca[imageFront]
    } else if (client === 'finnvo') {
        return finnvo[imageFront]
    } else if (client === 'credipress') {
        return credipress[imageFront]
    } else if (client === 'creditoo') {
        return creditoo[imageFront]
    } else if (client === 'liberate') {
        return liberate[imageFront]
    } else if (client === 'nauw') {
        return nauw[imageFront]
    } else if (client === 'tusolucion') {
        return tusolucion[imageFront]
    } else if (client === 'fie') {
        return fie[imageFront]
    } else if (client === 'lenddi') {
        return lenddi[imageFront]
    } else if (client === 'gym') {
        return gym[imageFront]
    } else if (client === 'inversiones') {
        return inversiones[imageFront]
    } else if (client === 'credinver') {
        return credinver[imageFront]
    } else if (client === 'freend') {
        return freend[imageFront]
    } else if (client === 'maxcredit') {
        return maxcredit[imageFront]
    } else if (client === 'sofi') {
        return sofi[imageFront]
    } else if (client === 'zali') {
        return zali[imageFront]
    } else if (client === 'libramoneda') {
        return libramoneda[imageFront]
    } else if (client === 'quickbank') {
        return quickbank[imageFront]
    } else if (client === 'finanzas') {
        return finanzas[imageFront]
    } else if (client === 'appqa2') {
        return mili[imageFront]
    }
    else return mili[imageFront]
}
export default image

import { mili } from './mili/TerCon'
import { bancaliv } from './bancaliv/TerCon'
import { fibanca } from './fibanca/TerCon'
import { finnvo } from './finnvo/TerCon'
import { credipress } from './credipress/TerCon'
import { liberate } from './liberate/TerCon'
import { nauw } from './nauw/TerCon'
import { fie } from './fie/TerCon'
import { lenddi } from './lenddi/TerCon'
import { gym } from './gym/TerCon'
import { inversiones } from './inversiones/TerCon'
import { selectClientName } from '../utils'
import { quickbank } from './quickbank/TerCon'
import { credinver } from './credinver/TerCon'
import { maxcredit } from './maxcredit/TerCon'
import { finanzas } from './finanzas/TerCon'
import { fembisol } from './fembisol/TerCon'
import { suvalor } from './suvalor/TerCon'
import { crecevalor } from './crecevalor/TerCon'
import { mifianza } from './mifianza/TerCon'
import { finzap } from './finzap/TerCon'

const parameters = {
    bancaliv: { name: 'BANCALIV', nameBrand: 'BANCA DE LIBRANZAS VIRTUALES S.A.S.' },
    mili: { name: 'MILI', nameBrand: 'MILI' },
    appqa: { name: 'MILI QA', nameBrand: 'MILI QA' },
    appqa2: { name: 'MILI QA', nameBrand: 'MILI QA' },
    rework: { name: 'MILI QA Rework', nameBrand: 'MILI QA Rework' },
    local: { name: 'MILI', nameBrand: 'MILI' },
    fibanca: { name: 'FIBANCA S.A.S', nameBrand: 'FIBANCA' },
    finnvo: { name: 'FINNVO', nameBrand: 'FINNVO' },
    credipress: { name: 'CREDIPRESS', nameBrand: 'CREDIPRESS S.A.S.' },
    liberate: { name: 'LIBERATECH', nameBrand: 'LIBERATECH S.A.S.' },
    nauw: { name: 'NAUW', nameBrand: 'CONASIE' },
    fie: { name: 'FIE', nameBrand: 'FIE S.A.S.' },
    lenddi: { name: 'GLOBAL MINDSET S.A.S', nameBrand: 'GLOBAL MINDSET S.A.S' },
    gym: { name: 'G&M SERVICIOS FINANCIEROS S.A.S.', nameBrand: 'G&M SERVICIOS FINANCIEROS' },
    mifianza: { name: 'MI FIANZA', nameBrand: 'MI FIANZA' },
    creditoo: {
        name: 'CREDITOO FABRICA DE CREDITO S.A.S.',
        nameBrand: 'CREDITOO FABRICA DE CREDITO',
    },
    fondo: { name: 'FONDO DE LA GENTE', nameBrand: 'FONDO DE LA GENTE' },
    tusolucion: { name: 'SERVICIOS Y FINANZAS OS S.A.S.', nameBrand: 'SERVICIOS Y FINANZAS OS' },
    inversiones: { name: 'INVERSIONES & NEGOCIOS S.A', nameBrand: 'INVERSIONES & NEGOCIOS' },
    credinver: { name: 'CREDINVER', nameBrand: 'CREDINVER S.A.S.' },
    freend: { name: 'FREEND S.A', nameBrand: 'FREEND' },
    maxcredit: { name: 'MAXCREDIT', nameBrand: 'MAXCREDIT S.A.S.' },
    sofi: { name: 'SOFI', nameBrand: 'SOFI' },
    zali: { name: 'ZALI', nameBrand: 'ZALI' },
    libramoneda: { name: 'LIBRAMONEDA', nameBrand: 'LIBRAMONEDA' },
    quickbank: { name: 'QUICK BANK', nameBrand: 'QUICK BANK' },
    finanzas: { name: 'FINANZAS 360', nameBrand: 'FINANZAS 360' },
    fembisol: {
        name: 'FEMBISOL',
        nameBrand: 'FUNDACIÒN EDUCATIVA METROPOLITANA DE BIENESTAR SOCIAL',
    },
    suvalor: { name: 'SU VALOR SU FUTURO', nameBrand: 'SU VALOR SU FUTURO' },
    crecevalor: { name: 'CRECE VALOR', nameBrand: 'CRECE VALOR' },
    finzap: { name: 'FIANZAP', nameBrand: 'FIANZAP' },
};

const termConditions = () => {
    const client = selectClientName();
    const params = parameters[client];
    if (client === 'bancaliv') {
        return bancaliv.TerCon(params)
    } else if (client === 'fembisol') {
        return fembisol.TerCon(params)
    } else if (client === 'finzap') {
        return finzap.TerCon(params)
    } else if (client === 'suvalor') {
        return suvalor.TerCon(params)
    } else if (client === 'mifianza') {
        return mifianza.TerCon(params)
    } else if (client === 'crecevalor') {
        return crecevalor.TerCon(params)
    } else if (client === 'fibanca') {
        return fibanca.TerCon(params)
    } else if (client === 'finnvo') {
        return finnvo.TerCon(params)
    } else if (client === 'credipress') {
        return credipress.TerCon(params)
    } else if (client === 'liberate') {
        return liberate.TerCon(params)
    } else if (client === 'nauw') {
        return nauw.TerCon(params)
    } else if (client === 'fie') {
        return fie.TerCon(params)
    } else if (client === 'lenddi') {
        return lenddi.TerCon(params)
    } else if (client === 'gym') {
        return credinver.TerCon(params)
    } else if (client === 'credinver') {
        return maxcredit.TerCon(params)
    } else if (client === 'maxcredit') {
        return gym.TerCon(params)
    } else if (client === 'inversiones') {
        return inversiones.TerCon(params)
    } else if (client === 'quickbank') {
        return quickbank.TerCon(params)
    } else if (client === 'finanzas') {
        return finanzas.TerCon(params)
    } else if (client === 'appqa2') {
        return mili.TerCon(params)
    }
    else return mili.TerCon(params)
}
export default termConditions